export default [
  {
    key: 'invoiceId',
    label: 'field.invoice_id',
    type: 'currency',

    disabled: true,
    cols: 6,
  },
  {
    key: 'accountName',
    label: 'field.account_name',
    type: 'text',
    cols: 6,
    disabled: true,
  },

  {
    key: 'accountUid',
    label: 'field.game_id',
    type: 'text',
    cols: 6,
    disabled: true,
  },
  {
    key: 'accountZoneid',
    label: 'field.zone_id',
    type: 'text',
    cols: 6,
    disabled: true,
  },

  {
    key: 'diamondAmount',
    label: 'field.amount_diamond',
    type: 'currency',

    disabled: true,
    cols: 6,
  },
  {
    key: 'price',
    label: 'field.price',
    type: 'currency',
    disabled: true,
    cols: 6,
  },
  {
    key: 'wholeSaleName',
    label: 'field.wholeSale',
    type: 'text',
    cols: 12,
    disabled: true,
  },
  {
    key: 'receiptUrl',
    label: 'field.receipt',
    type: 'single-image',
    cols: 12,
    ph: 500,
    pw: 500,
    initKey: 'mediaFile',
    fullWidth: false,
    disabled: true,
  },
  {
    key: 'orderType',
    label: 'status.order_type',
    type: 'text',
    cols: 12,
    disabled: true,
  },
  {
    key: 'createdAt',
    label: 'field.createDate',
    type: 'date',
    cols: 12,
  },
]
