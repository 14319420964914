<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <b-card>
      <n-input-view :fields="fields" v-model="data" :initValue="initData">
        <template #receiptUrl="item">
          <div v-if="!data.aba">
            <b-form-group :label-for="item.field.key" :class="item.field.class">
              <n-single-image-uploader
                v-if="item.field.type === 'single-image'"
                :pw="item.field.pw"
                :ph="item.field.ph"
                :fullWidth="item.field.fullWidth"
                :image="data.receiptUrl"
                v-model="data[item.field.key]"
                :readonly="item.field.disabled"
              ></n-single-image-uploader>
            </b-form-group>
          </div>
          <div v-else-if="data.type != 'manual'">
            <b-form-group :label-for="item.field.key" :class="item.field.class">
              <label>{{ $t('field.payment') }} </label>
              <b-form-input
                v-model="data.tranId"
                :placeholder="$t('field.payment')"
                :name="'tranId'"
                :disabled="true"
                autocomplete="off"
              />
            </b-form-group>
          </div>
          <div v-else></div>
        </template>
      </n-input-view>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mt-1"
            @click="back"
          >
            {{ $t('button.back') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
  BTab,
  BTabs,
  BFormTextarea,
} from 'bootstrap-vue'
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonDelete from '@/components/NButtonDelete'
import NFormConfirmation from '@/components/NFormConfirmation'
import NInputView from '@/components/NInputView'
import formInput from './formInput'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import NTimeInput from '@/components/NTimeInput.vue'
import NSingleImageUploader from '@/components/NSingleImageUploader'

const TransactionRepository = Repository.get('transaction')

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonDelete,
    NFormConfirmation,
    NInputView,
    ValidationProvider,
    ValidationObserver,
    BTab,
    BTabs,
    BFormTextarea,
    NTimeInput,
    NSingleImageUploader,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {},
      disable: true,
      initData: {},
      loading: false,
    }
  },
  mounted() {
    this.show()
  },
  methods: {
    show() {
      TransactionRepository.show(this.$route.params.id)
        .then((response) => {
          if (response?.data?.data) {
            this.initData = {
              ...response.data.data,
            }
            this.data = {
              ...response.data.data,
            }
          }
        })
        .catch()
        .then(() => {})
    },
    deleteItem() {
      TransactionRepository.delete(this.$route.params.id)
        .then((response) => {
          this.back()
        })
        .catch((error) => {})
        .then(() => {
          this.loading = false
        })
    },
    back() {
      this.$router.back()
    },
  },
  setup() {
    const fields = [...formInput]
    return { fields }
  },
}
</script>
